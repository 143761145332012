import React from "react";
import { Link } from "gatsby";
import View from "../View";
import { getUser } from "../../utils/auth";
import { GetDateTimePartsFor, FromHtmlFormatConvert } from "../../Services/DateTimeService";
import VerifiedTickSVG from "../../svgs/tick.svg";
import firebase from "gatsby-plugin-firebase";
import { useObject } from "react-firebase-hooks/database";

/**
 * Generates a component that displays the users second vaccination record.
 */
const Vaccine2Record = () => {
  const user = getUser();
  const { displayName, uid } = user;
  const [value, error] = useObject(firebase.database().ref(`VaccinationRecords/${uid}`));

  const textFieldClassName = "outline-none text-sm p-2 w-11/12 my-4 border border-t-0 border-l-0 border-r-0";

  return (
    <View title="">
      {error && <div>{error.message}</div>}
      <div className="container py-6 mx-auto">
        <h1 className="w-full pb-4 text-xl text-center">{displayName}</h1>
        <div className="w-full my-2 text-center">
          <p className="w-full text-base font-bold text-center text-gray-600">Vaccine 2</p>
          <div className="flex justify-center w-full">
            <p className="w-1/2 mt-2 font-bold text-center text-white bg-green-600 border border-green-600">Verified Data</p>
          </div>
        </div>
        <div className="flex items-start justify-center w-full mt-4 text-center">
          <p className="w-full py-1 text-xs text-center text-gray-600 align-middle">
            <span>
              <VerifiedTickSVG className="inline-block h-full mr-2 -mt-1" />
            </span>
            Verified On {value && GetDateTimePartsFor(value.val().Vaccine2DateTimeStamp)[0]}
          </p>
        </div>
        <div className="flex items-start justify-center w-full mb-2 text-center">
          <p className="w-full py-1 text-xs text-center text-gray-600 align-middle">At {value && GetDateTimePartsFor(value.val().Vaccine2DateTimeStamp)[1]}</p>
        </div>

        <div className="w-full mx-auto my-2">
          <div className="px-4">
            <p className={textFieldClassName}>{value && value.val().Vaccine2Manufacturer}</p>
          </div>
          <div className="px-4">
            <p className={textFieldClassName}>{value && value.val().Vaccine2BatchNumber}</p>
          </div>
          <div className="px-4">
            <p className={textFieldClassName}>{value && FromHtmlFormatConvert(value.val().Vaccine2Date)}</p>
          </div>
          <div className="px-4">{value && value.val().Vaccine2ImageUrl && <img className="w-8/12 mx-auto " src={value.val().Vaccine2ImageUrl}></img>}</div>
          <br />
          <div className="flex justify-center w-full">
            <Link to="/app/profile" className="w-full p-2 mx-4 text-sm text-white bg-green-600 border border-green-600 rounded-lg shadow-md outline-none hover:bg-green-800">
              Home
            </Link>
          </div>
        </div>
      </div>
    </View>
  );
};

export default Vaccine2Record;
