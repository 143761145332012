import React, { useState } from "react";
import { navigate, Link } from "gatsby";
import { useForm } from "react-hook-form";
import View from "../View";
import { getUser } from "../../utils/auth";
import { GetCurrentDateTimeStamp } from "../../Services/DateTimeService";
import firebase from "gatsby-plugin-firebase";
import { useStorage } from "../../hooks/useStorage";

/**
 * Generates a component that provides the UI for a user to record their second vaccination related data.
 */
const Vaccine2Verification = () => {
  const user = getUser();
  const { displayName, uid } = user;
  const { register, handleSubmit, errors } = useForm();

  const textFieldClassName = "outline-none bg-white text-xs overflow-hidden px-2 py-1 w-full my-2 border border-green-600 border-t-0 border-l-0 border-r-0";
  const errorsStyle = "inline-block text-red-DEFAULT text-xs italic text-red-landingPage800";
  const labelStyles = "text-xs w-full text-gray-600";

  const [file, setFile] = useState(null);
  const [error, setError] = useState(null);
  const { progress, url } = useStorage(file);

  const fileTypes = ["image/png", "image/jpeg", "image/jpg"];

  /**
   * Generates a string of allowed file types for the FileUpload element's filetypes attribute.
   *
   * @param {*} fileTypes An array of strings specifying the allowed file extensions to be uploaded.
   */
  const getFileUploadFileTypesStringFrom = (fileTypes) => `"${fileTypes.join(", ")}"`;

  /**
   * Saves the user entered, second vaccination, data to the underlying database.
   *
   * The data object is modified to store the uploaded file name and url before saving.
   *
   * @param {Object} data A JSON object containing the form data entered by the user.
   */
  const onSubmit = (data) => {
    data.Vaccine2ImageVerification = file.name;
    data.Vaccine2ImageUrl = url;

    const vrRef = firebase.database().ref(`VaccinationRecords/${uid}`);
    vrRef.once("value", function (snapshot) {
      if (snapshot.exists()) {
        snapshot.ref.update(data);
        alert("You vaccination has been verified and saved.");
        navigate("/app/profile");
      }
    });
  };

  /**
   * Uploads the selected image to the underlying blob storage.
   *
   * The file name is modified to prefix the users Id value to prevent clashing.
   *
   * @param {Element} e The FileUpload control which is used to upload the vaccination proof image.
   */
  const handleChange = (e) => {
    let uploadedFile = e.target.files[0];
    let selectedFile = new File([uploadedFile], `${uid}_${uploadedFile.name}`, {
      type: uploadedFile.type,
      lastModified: uploadedFile.lastModified,
    });

    if (selectedFile) {
      if (fileTypes.includes(selectedFile.type)) {
        setError(null);
        setFile(selectedFile);
      } else {
        setFile(null);
        setError("Please select an image file (png or jpg)");
      }
    }
  };

  return (
    <View title="">
      <div className="container py-6 mx-auto">
        <h1 className="w-full pb-4 text-xl text-center">{displayName}</h1>
        <div className="w-full my-2 text-center">
          <p className="w-full px-10 py-1 text-xs text-center text-gray-600">Please enter the details of your second vaccine below.</p>
          <p className="w-full px-10 py-1 text-xs text-center text-gray-600">Please be accurate as once verified this is final.</p>
        </div>
        <div className="w-full mx-auto my-8">
          <form className="my-2" onSubmit={handleSubmit(onSubmit)}>
            <div className="px-4">
              <label className={labelStyles} htmlFor="Vaccine2Manufacturer">
                Enter the <span className="text-green-600">full name</span> of the vaccine
              </label>
              <input name="Vaccine2Manufacturer" className={textFieldClassName} placeholder="Vaccine 2 Full Name" defaultValue="" ref={register({ required: true })} />
              {errors.Vaccine2Manufacturer && <p className={errorsStyle}>Please enter the Vaccine Manufacturer Name</p>}
            </div>
            <div className="px-4">
              <label className={labelStyles} htmlFor="Vaccine2BatchNumber">
                Enter the vaccine <span className="text-green-600">batch number</span>
              </label>
              <input name="Vaccine2BatchNumber" className={textFieldClassName} placeholder="Vaccine 2 Batch Number" defaultValue="" ref={register({ required: true })} />
              {errors.Vaccine2BatchNumber && <p className={errorsStyle}>Please enter the Vaccine's Batch Number</p>}
            </div>
            <div className="px-4">
              <label className={labelStyles} htmlFor="Vaccine2Date">
                Select the <span className="text-green-600">date</span> you received the vaccine
              </label>
              <input name="Vaccine2Date" type="date" className={textFieldClassName} placeholder="Vaccine 2 Date (mm/dd/yyyy)" defaultValue="" ref={register({ required: true })} />
              {errors.Vaccine2Date && <p className={errorsStyle}>Please enter the date you received your second vaccination</p>}
            </div>
            <div className="px-4">
              <p className="w-full px-2 pt-4 pb-6 text-xs text-center text-gray-600">
                In order to generate your Biosxan, you are required to <span className="text-green-600">upload a photo</span> of the vaccine record you where provided at the time of your vaccination.
              </p>
            </div>
            <div className="px-4">
              <label className={labelStyles} htmlFor="Vaccine2Date">
                Select the <span className="text-green-600">image</span> of your vaccine record
              </label>
              <input
                name="Vaccine2ImageVerification"
                onChange={handleChange}
                type="file"
                className={textFieldClassName}
                filetypes={getFileUploadFileTypesStringFrom(fileTypes)}
                placeholder="Vaccine 2 Image"
                defaultValue=""
                ref={register({ required: true })}
              />
              {errors.Vaccine2Date && <p className={errorsStyle}>Please upload your visual proof of your second vaccination</p>}
              {error && <p className="text-xs text-red-DEFAULT">{error}</p>}
              {file && <p className="py-2 text-sm font-bold text-green-600">Please Wait - {progress}% Uploaded</p>}
              {url && <img className="w-8/12 mx-auto " src={url}></img>}
            </div>
            {/*  */}
            <input name="Vaccine2DateTimeStamp" type="hidden" defaultValue={GetCurrentDateTimeStamp()} ref={register()} />
            <input name="Vaccine2Verified" type="hidden" defaultValue="true" ref={register()} />
            <div className="w-full my-4 text-center">
              <p className="w-full px-10 py-1 text-xs text-center text-gray-600">Once you click the Save button, Biosxan will save and verify your vaccination record.</p>
              <p className="w-full px-10 py-1 text-xs text-center text-gray-600">
                Please ensure your data is correct and then click <span className="text-green-600">Save</span>
              </p>
            </div>
            <div className="flex justify-center w-full my-4">
              <input
                disabled={url && url.length > 0 ? "" : "disabled"}
                className="w-full p-2 mx-4 text-sm font-bold text-white bg-green-600 border border-green-600 rounded-lg outline-none hover:bg-green-800 disabled:opacity-25"
                value="Save"
                type="submit"
              />
            </div>
            <div className="flex justify-center w-full my-4">
              <Link to="/app/profile" className="w-full p-2 mx-4 text-sm font-bold text-white bg-green-600 border border-green-600 rounded-lg shadow-md outline-none hover:bg-green-800">
                Home
              </Link>
            </div>
          </form>
        </div>
      </div>
    </View>
  );
};

export default Vaccine2Verification;
