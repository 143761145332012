import React from "react";
import firebase from "gatsby-plugin-firebase";
import { Link, navigate } from "gatsby";
import View from "../View";
import { getUser, logout } from "../../utils/auth";
import { useObject } from "react-firebase-hooks/database";

/**
 * Generates a component that renders the users profile and status content.
 *
 * The component adapts to show the relevant UI elements based on the data capture by the user.
 */
const UserHome = () => {
  const user = getUser();
  const { displayName, uid } = user;
  const [value, loading, error] = useObject(firebase.database().ref(`VaccinationRecords/${uid}`));

  const verificationButtonStyle = "w-full outline-none shadow-md mx-4 p-2 text-sm text-white bg-green-600 border border-green-600 rounded-lg hover:bg-green-800";

  /**
   * Generates a unique url using the users id. This is applied to the QR code that is generated once a user is verified.
   *
   * @param {String} uid A string containing the users id value from the auth service.
   * @returns A String containing the generated url to be applied to the QR code.
   */
  const generateValidationUrlUsing = (uid) => {
    if (typeof window === "undefined") {
      return "";
    }

    const origin = new URL(window.location.href).origin;
    return `${origin}/validate?x=${uid}`;
  };

  return (
    <View title="">
      {error && <div>{error.message}</div>}
      <div className="container py-6 mx-auto">
        <h1 className="w-full text-xl text-center">{displayName}</h1>
        {value && value.val() && (
          <>
            <div className={`w-full my-2 text-center ${loading === true ? `hidden` : value && value.val().Vaccine1Verified === "true" ? `hidden` : `block`}`}>
              <p className="w-full px-4 py-1 text-xs text-center text-gray-600">You have not verified your vaccination status.</p>
              <p className="w-full px-4 py-1 text-xs font-bold text-center text-gray-600">
                Please click the <span className="text-green-600">Record Vaccine 1</span> button below to get started.
              </p>
              <p className="w-full px-4 py-1 text-xs text-center text-gray-600">Your Biosxan will then be available below for verification by third parties.</p>
            </div>

            <div className={`w-full my-2 text-center ${loading === true ? `hidden` : value && value.val().Vaccine1Verified === "true" ? `block` : `hidden`}`}>
              <div className={`${loading === true ? `hidden` : value && value.val().Vaccine2Verified === "true" ? `hidden` : `block`}`}>
                <p className="w-full px-4 py-1 text-xs text-center text-gray-600">
                  You have <span className="text-green-600">verified</span> your first vaccination.
                </p>
                <p className="w-full px-4 py-1 text-xs text-center text-gray-600">Your Biosxan, below, can be used to verify your record.</p>
              </div>
            </div>

            <div className={`w-full my-2 text-center ${loading === true ? `hidden` : value && value.val().Vaccine2Verified === "true" ? `block` : `hidden`}`}>
              <p className="w-full px-4 py-1 text-xs text-center text-gray-600">
                You have <span className="text-green-600">verified</span> both of your vaccinations.
              </p>
              <p className="w-full px-4 py-1 text-xs text-center text-gray-600">Your Biosxan, below, can be used to verify your record.</p>
            </div>

            <div className={`flex justify-center w-full my-4 ${loading === true ? `hidden` : value && value.val().Vaccine1Verified === "true" ? `hidden` : `block`}`}>
              <img
                className="opacity-25"
                src="https://www.unitag.io/qreator/generate?crs=Ppv8rOENN3V1lAwTz82zPgJCeRt23RLSE9SsU4N%252BrxqX4N9KhizbkATejaCcMBan9jqG%252FxftIvOPi1JF2kaypKdwoiOWaXc7QP7mwNyUOPzUfgbeCrBpD%252B3zdzsDPleGVKdO9VfG2niFyHUZ0GDFYQ%253D%253D&crd=fhOysE0g3Bah%252BuqXA7NPQ87MoHrnzb%252BauJLKoOEbJsq9dXSG3NLxkWyHJ0J61LAt5YXymSnz34s%252FpovsgvUx5A%253D%253D"
                alt="Biosxan - Don't Mask it. Prove it."
              />
            </div>

            <div className={`flex justify-center w-full mt-6 mb-12 ${loading === true ? `hidden` : value && value.val().Vaccine1Verified === "true" ? `block` : `hidden`}`}>
              <img className="" src={`https://api.qrserver.com/v1/create-qr-code/?data=${value && generateValidationUrlUsing(value.key)}&amp;size=280x280`} alt="" title="" />
            </div>

            <div className={`w-full flex justify-center font-bold my-2 text-center ${loading === true ? `hidden` : value && value.val().Vaccine1Verified === "true" ? `hidden` : `block`}`}>
              <Link className={verificationButtonStyle} to="/app/vaccine1verification">
                Record Vaccine 1 Details
              </Link>
            </div>

            <div className={`w-full flex justify-center font-bold my-2 text-center ${loading === true ? `hidden` : value && value.val().Vaccine1Verified === "true" ? `block` : `hidden`}`}>
              <Link className={verificationButtonStyle} to="/app/vaccine1details">
                View Vaccine 1 Details
              </Link>
            </div>

            <div className={`${loading === true ? `hidden` : value && value.val().Vaccine1Verified === "true" ? `block` : `hidden`}`}>
              <div className={`w-full flex justify-center my-2 font-bold text-center ${loading === true ? `hidden` : value && value.val().Vaccine2Verified === "true" ? `hidden` : `block`}`}>
                <Link className={verificationButtonStyle} to="/app/vaccine2verification">
                  Record Vaccine 2 Details
                </Link>
              </div>
            </div>

            <div className={`w-full flex justify-center my-2 font-bold text-center ${loading === true ? `hidden` : value && value.val().Vaccine2Verified === "true" ? `block` : `hidden`}`}>
              <Link className={verificationButtonStyle} to="/app/vaccine2details">
                View Vaccine 2 Details
              </Link>
            </div>
          </>
        )}
        <div className="flex justify-center w-full my-2 text-center">
          <a
            href="/"
            className="w-full p-2 mx-4 text-sm font-bold text-white bg-gray-600 border border-gray-600 rounded-lg shadow-md hover:bg-gray-800"
            onClick={(event) => {
              event.preventDefault();
              logout(firebase).then(() => navigate(`/`));
            }}
          >
            Log Out
          </a>
        </div>
      </div>
    </View>
  );
};

export default UserHome;
