import { useState, useEffect } from "react";
import firebase from "gatsby-plugin-firebase";

/**
 * A hook used to upload files to the underlying Firebase blob storage account.
 *
 * The hook provides access to the final url for the file as well as the upload progress and any errors encountered.
 *
 * @param {File} file The File object to save to the underlying blog storage account. Note, no clash handling is included and uploads
 * will not check for existing files.
 */
export const useStorage = (file) => {
  const [progress, setProgress] = useState(0);
  const [error, setError] = useState(null);
  const [url, setUrl] = useState(null);

  const projectStorage = firebase.storage();

  useEffect(() => {
    if (file) {
      const storageRef = projectStorage.ref(file.name);

      storageRef.put(file).on(
        "state_changed",
        (snap) => {
          let percentage = Math.round((snap.bytesTransferred / snap.totalBytes) * 100);
          setProgress(percentage);
        },
        (err) => {
          setError(err);
        },
        async () => {
          const downloadUrl = await storageRef.getDownloadURL();
          setUrl(downloadUrl);
        }
      );
    }
  }, [file]);

  return { progress, url, error };
};
