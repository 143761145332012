import React from "react";
import PropTypes from "prop-types";
import { navigate } from "gatsby";
import { isLoggedIn } from "../utils/auth";

/**
 * Provides a wrapper around routes that are only accessible to authenticated users.
 */
const PrivateRoute = ({ component: Component, location, ...rest }) => {
  if (!isLoggedIn() && location.pathname !== `/app/login`) {
    // If we’re not logged in, redirect to the home page.
    navigate(`/app/login`, { replace: true });
    return null;
  }

  return <Component {...rest} />;
};

PrivateRoute.propTypes = {
  component: PropTypes.any.isRequired,
};

export default PrivateRoute;
