import React from "react";
import PropTypes from "prop-types";
import BioSxanLogoSVG from "../../svgs/biosxanicon.svg";
import BioSxanAuthPageLogoSVG from "../../svgs/biosxanlogowhite.svg";
import TCCShieldLogoSVG from "../../svgs/tccshield.svg";

/**
 * Generates a component to wrap each child component with a standard layout.
 *
 * @param {String} title A String containing the title to be displayed to the user by the child component.
 * @param {Boolean} isAuthScreen A boolean that indicates of the view is wrapping the authentication screen or not. Defaults to false.
 * @param {Element} children The child element to render within the view.
 */
const View = ({ title, isAuthScreen = false, children }) => {
  return (
    <div className="flex min-h-screen font-body">
      <div className="flex flex-wrap justify-center w-full overflow-hidden">
        <div className="flex justify-center w-full overflow-hidden">
          {isAuthScreen && <BioSxanAuthPageLogoSVG className="block w-full h-16 p-2 my-4 text-center" />}
          {!isAuthScreen && <BioSxanLogoSVG className="block w-full h-16 p-2 my-4 text-center" />}
        </div>

        {isAuthScreen && (
          <div className="">
            <div className="px-10 text-sm leading-7 text-center text-white">
              <p className="pb-4 font-bold">Biosxan Is The Secure Covid Vaccination Repository</p>
              <p>It provides an encrypted QR code that you can use to prove your Covid vaccination status.</p>
            </div>
          </div>
        )}

        <div className="w-10/12 overflow-hidden text-center">
          <div className="w-full my-2 bg-white rounded-md shadow-md">
            <h1 className="w-full py-4 text-xl text-center">{title}</h1>
            <div className="">{children}</div>
          </div>
        </div>

        <div className="w-full p-6 overflow-hidden">
          <TCCShieldLogoSVG className="block w-full h-8 p-2 text-center" />
        </div>
      </div>
    </div>
  );
};

View.propTypes = {
  title: PropTypes.string.isRequired,
};

export default View;
