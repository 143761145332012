import React from "react";
import { Router } from "@reach/router";
import Layout from "../components/Layout";
import Login from "../components/Login";
import PrivateRoute from "../components/PrivateRoute";
import UserHome from "../components/UserHome";
import Vaccine1Verification from "../components/Vaccine1Verification";
import Vaccine1Record from "../components/Vaccine1Record";
import Vaccine2Verification from "../components/Vaccine2Verification";
import Vaccine2Record from "../components/Vaccine2Record";

/**
 * Application main entry point.
 */
const App = () => (
  <Layout>
    <Router>
      <PrivateRoute path="/app/profile" component={UserHome} />
      <PrivateRoute path="/app/vaccine1verification" component={Vaccine1Verification} />
      <PrivateRoute path="/app/vaccine1details" component={Vaccine1Record} />
      <PrivateRoute path="/app/vaccine2verification" component={Vaccine2Verification} />
      <PrivateRoute path="/app/vaccine2details" component={Vaccine2Record} />
      <Login path="/app/login" />
    </Router>
  </Layout>
);

export default App;
