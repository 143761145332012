import React from "react";
import { navigate } from "@reach/router";
import View from "./View";
import StyledFirebaseAuth from "react-firebaseui/StyledFirebaseAuth";
import { setUser, isLoggedIn } from "../utils/auth";
import firebase from "gatsby-plugin-firebase";
import { FirebaseAuth } from "react-firebaseui";

/**
 * Generates a component that consumes the Firebase auth service to provide sign in and auth functionality.
 */
const Login = () => {
  if (isLoggedIn()) {
    navigate(`/app/profile`);
  }

  /**
   * Creates an empty, default, record for the user.
   *
   * @param {String} uid The authenticated users uid.
   */
  const setUserRecord = (uid) => {
    const vrRef = firebase.database().ref(`VaccinationRecords/${uid}`);
    const newRecord = {
      Vaccine1Manufacturer: " ",
      Vaccine1BatchNumber: "",
      Vaccine1Date: "",
      Vaccine1ImageVerification: "",
      Vaccine1ImageUrl: "",
      Vaccine1DateTimeStamp: "",
      Vaccine1Verified: "false",
      Vaccine2Manufacturer: "",
      Vaccine2BatchNumber: "",
      Vaccine2Date: "",
      Vaccine2ImageVerification: "",
      Vaccine2ImageUrl: "",
      Vaccine2DateTimeStamp: "",
      Vaccine2Verified: "false",
    };

    vrRef.once("value", function (snapshot) {
      if (!snapshot.exists()) {
        vrRef.set(newRecord);
      }
    });
  };

  /**
   * Displays the Firebase Auth UI to the user.
   *
   * This function will add an empty user record to the database is one is not found and redirects the user to the profile page on successful login.
   *
   * @param {FirebaseAuth.Auth} auth A Firebase.Auth provide instance used to generate the UI.
   */
  function getUiConfig(auth) {
    return {
      signInFlow: "popup",
      signInOptions: [auth.GoogleAuthProvider.PROVIDER_ID, auth.EmailAuthProvider.PROVIDER_ID],
      callbacks: {
        signInSuccessWithAuthResult: (result) => {
          setUser(result.user);
          setUserRecord(result.user.uid);
          navigate("/app/profile");
        },
      },
    };
  }

  return (
    <View title="Log In" isAuthScreen={true}>
      <div className="container py-6 mx-auto font-body">
        <p className="px-4 text-sm text-gray-600">Please sign in using your preferred option below</p>
        {firebase && <StyledFirebaseAuth uiConfig={getUiConfig(firebase.auth)} firebaseAuth={firebase.auth()} />}
      </div>
    </View>
  );
};

export default Login;
